@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	p,ul li {
		padding:0;
		margin:0;
		font-size:20px;
		line-height:34px;
		color:#000;

		@include xl {
			font-size:14px;
			line-height:22px;
		}

		a {
			color:#888;
		}

	}

	h1 {
	    color:#000;
	    padding:0;
	    margin:0;
	    font-size:40px;
		line-height:40px;
		font-weight:bold;
	    margin-bottom:10px;
	    color:#000;

	    @include md {
	    	font-size:20px;
	    }

	    span {
	    	color:#421567;
	    }

	}

	h2 {
	    color:#000;
	    padding:0;
	    margin:0;
	    font-size:30px;
		line-height:30px;
		font-weight:bold;
	    margin-bottom:10px;
	   	color:#421567;

	   	@include md {
	    	font-size:15px;
	    }
	}

}