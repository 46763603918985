@import "_default.scss_include-mixins";

body {
	font-family:'Open Sans', serif;
	background-color:#fff;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}

/*
Marges
*/
.page-marge {
	float:left;
	width:100%;
	margin-top:120px;
	padding-bottom:40px;
}

@keyframes blink {
	0% {opacity: 0}
	50%{opacity: 1}
	100% {opacity: 0}
}