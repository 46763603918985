@import "_default.scss_include-mixins";

.popup--content {
	float:left;
	width::700px;

	@include sm {
		width:280px;
	}

	&.bigger {
		width:900px;

		@include sm {
			width:280px;
		}
	}

	&.small {
		width:500px;

		@include sm {
			width:280px;
		}
	}

	.content {
		float:left;
		width:100%;
		color:#000;
		background-color:#fff;
		padding:40px;
		padding-bottom:30px;
		padding-top:30px;
		text-align:left;

		.img-maxwidth {
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);

			@include sm {
				position:relative;
				top:auto;
				left:auto;
				transform:none;
			}
		}

		 .swiper {
	        width: 100%;
	        height: 100%;
	        overflow:hidden;

			.image {
				float:left;
				width:100%;
				height:400px;
				border-radius:15px;
				background-color:#e1e1e1;
				background-repeat:no-repeat;
				background-position:center;
				background-size:cover;
			}

		}

		@include sm {
			padding:20px;
		}

	}

}
	