@import "_default.scss_include-mixins";

.hotspot {

	position:relative;
	height:80px;
	width:245px;
	transform:scale(0.9);

	@include xl {
		transform:scale(0.5);
	}

	&.hotspot_2 {
		height:80px;
		width:245px;
	}

	&.no-label {
		width:80px;
	}


	// &:hover {

	// 	transform:scale(1);
		
	// 	.arrow {
	// 		background-color:#000;
	// 	}

	// }

	@include sm {
		transform:scale(0.5);
	}

	/*
	Override: Button - Icon Right
	Override: Button - Icon Right
	Override: Button - Icon Right
	Override: Button - Icon Right
	*/
	&.right {
		left:auto;
		right:0;

		.label {
			right:65px;
			padding-right:20px;
			border-radius:20px 0 0 20px;
		}

		.arrow {
			left:auto;
			right:0;
		}

		.label {
			left:auto;
			right:65px;
		}
	}

	/*
	Button: Icon left 
	Button: Icon left 
	Button: Icon left 
	Button: Icon left 
	*/
	.arrow {
		position:absolute;
		left:0;
		height:80px;
		width:80px;
		background-color:#ffed00;
		border-radius:50%;
		box-shadow:0 0 5px rgba(0,0,0,0.5);
		z-index:2;

		&.yellow {
			background-color:#ffed00;
		}

		.icon {
			position:absolute;
			top:50%;
			left:50%;
			width:40px;
			height:40px;
			background-image:url('../img/static/icon-arrow.png');
			background-size:contain;
			background-repeat:no-repeat;
			background-position:center;

			&.forward {
				transform:translate(-50%,-50%) rotate(270deg);
			}
			&.left {
				transform:translate(-50%,-50%) rotate(180deg);
			}
			&.right {
				transform:translate(-50%,-50%) rotate(360deg);
			}
			&.backwards {
				transform:translate(-50%,-50%) rotate(90deg);
			}

			&.info {
				background-image:url('../img/static/icon-info.png');
				transform:translate(-50%,-50%);
				width:60px;
				height:60px;
			}

		}

	}

	.label {
		position:absolute;
		left:65px;
		top:50%;
		transform:translate(0,-50%);
		padding:10px;
		padding-left:20px;
		width:180px;
		background-color:#fff;
		box-shadow:0 0 10px rgba(0,0,0,0.1);
		border-radius: 0 20px 20px 0;
		z-index:1;

		.title {
			float:left;
			width:100%;
			font-size:16px;
			font-weight:bold;
			line-height:20px;
			text-align:center;

			@include sm {
				font-size:12px;
			}

		}

	}

}