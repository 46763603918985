@import "_default.scss_include-mixins";

.button {
	
	cursor:pointer;
	
	&.button-1 {
		display:inline-block;
		position:relative;
		text-decoration:none;

		text-align:center;
		padding:5px;
		padding-left:20px;
		padding-right:20px;
		margin-top:20px;
		margin-left:5px;
		margin-right:5px;
		border-radius:50px;
		background-color:#97BF26;
		width:300px;
		height:40px;

		transition:all 0.2s ease-in-out;

		&.px210 {
			width:210px;
		}

		.icon {
			position:absolute;
			right:0;
			top:50%;
			transform:translate(-25px,-50%);

			width:18px;
			height:18px;

			transition:all 0.2s ease-in-out;

			svg {
				fill:#fff;
			}

		}

		.label {
			position:absolute;
			top:50%;
			transform:translate(15px,-50%);

			font-size:20px;
			line-height:20px;
			margin-top:-1px;
			color:#fff !important;
			text-decoration:none;

			font-family:'Open Sans';
			font-weight:bold;

			@include md {
				font-size:18px;
			}
		}

		&:hover,
		&.active {
			background-color:#421567;

			.icon {
				transform:translate(-23px,-50%);
			}
		}

		&.inline {
			float:inherit;
			width:auto;
		}
		
	}

	&.button-2 {
		display:inline-block;
		position:relative;
		text-decoration:none;

		text-align:center;
		padding:10px;
		padding-left:20px;
		padding-right:20px;
		
		border-radius:50px;
		background-color:#97BF26;

		transition:all 0.2s ease-in-out;

		@include md {
			padding:3px;
			padding-left:10px;
			padding-right:10px;
		}

		.label {
			float:left;

			font-size:20px;
			line-height:20px;
			margin-top:-1px;
			color:#fff !important;
			text-decoration:none;

			font-family:'Open Sans';
			font-weight:bold;

			@include md {
				font-size:12px;
			}
		}

		&:hover,
		&.active {
			background-color:#421567;

			.icon {
				transform:translate(-23px,-50%);
			}
		}

		&.inline {
			float:inherit;
			width:auto;
		}
		
	}

}