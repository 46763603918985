@import "_default.scss_include-mixins";	 

header {

	.mapssmall {
		position:fixed;
		bottom:20px;
		left:20px;
		width:20vw;
		height:100vh;
		z-index:25;

		// &.floor-1 {
		// 	height:270px;
		// }
		// &.floor-2 {
		// 	height:600px;
		// }
		// &.floor-3 {
		// 	height:100%;
		// }


		.big {
			position:absolute;
			bottom:0;
			left:0;
			width:100%;
			font-size:1vw;
			font-weight:bold;
			color:#fff;
			text-align:center;
			cursor:pointer;
			text-shadow:0 0 4px rgba(0,0,0,0.1);

			span {
				text-decoration:underline;
			}
		}

		.floor,
		.floorplan {
			display:none;
			float:left;
			width:100%;

			&.active {
				display:block;
			}
		}

		.maps {
			float:left;
			width:100%;
			// height:calc(100vh - 240px);
			position:absolute;
			bottom:20px;

			@include sm {
				height:calc(100vh - 120px);
			}

			.floorplan {
				float:left;
				width:99%;

				.responsive-map {
					position:relative;
					display: inline-block;

					img {
					  display: block;
					  max-width: 99%;
					  height: auto;
					}

					.marker {
						position:absolute;
						width:10px;
						height:10px;
						top:0;
						left:0;
						border-radius:50%;
						background-color:#000;
						z-index:2;
						cursor:pointer;

						&.active {
							background-color:#ffed00;
							border:1px solid #000;
							animation: 1s blink ease infinite;
						}

						&:hover {
							background-color:#ffed00;
							transition:0.4s all;
						}

						@include xl {
							width:10px;
							height:10px;
						}

						@include sm {

							div {
								position:absolute;
								top:50%;
								left:50%;
								width:30px;
								height:30px;
								transform:translate(-50%,-50%);
								// border:1px solid #000;
							}
							
						}

						/*
						Floor 1 ******************************
						Floor 1 ******************************
						Floor 1 ******************************
						Floor 1 ******************************
						*/
						&[data-scene="entrance-RTM-B"] {
							left: 42.5%;
							top: 82%;
						}

						&[data-scene="hal-1"] {
							left:20%;
							top:19%;
						}

						&[data-scene="hal-2"] {
							left:60.5%;
							top:40%;
						}

						&[data-scene="hal-3"] {
							left:60.5%;
							top:62%;
						}

						&[data-scene="hal-4"] {
							left:74.5%;
							top:40%;
						}

						&[data-scene="hal-5"] {
							left:74.5%;
							top:62%;
						}

						&[data-scene="hal-6"] {
							left:89%;
							top:52%;
						}

						&[data-scene="bar-restaurant-seventy-one"] {
							left: 47%;
							top: 64.5%;
						}

						&[data-scene="ahoy-plaza"] {
							left:47%;
							top:52%;
						}

						&[data-scene="ahoy-arena"] {
							left:25.5%;
							top:52%;
						}

						&[data-scene="entree-ahoy-arena"] {
							left:25.5%;
							top:73%;
						}

						&[data-scene="rtm-stage-1"] {
							left: 66.5%;
							top: 81%;
						}

						/*
						Floor 2 ******************************
						Floor 2 ******************************
						Floor 2 ******************************
						Floor 2 ******************************
						*/
						&[data-scene="dock-1"] {
							left:18%;
							top:25.7%;
						}

						&[data-scene="dock-3"] {
							left:41%;
							top:28.5%;
						}

						&[data-scene="dock-4"] {
							left:41%;
							top:35%;
						}

						&[data-scene="dock-6"] {
							left:49.5%;
							top:61.7%
						}

						&[data-scene="dock-10"] {
							left:65%;
							top:88.5%;
						}

						&[data-scene="dock-12"] {
							left:39%;
							top:72.3%;
						}

						&[data-scene="dock-13"] {
							left:37%;
							top:87.7%;
						}

						&[data-scene="dock-14"] {
							left:19.5%;
							top:87.89%;
						}

						&[data-scene="dock-15"] {
							left:19.5%;
							top: 77.5%;
						}

						&[data-scene="dock-16"] {
							left: 20%;
							top: 42%;
						}

						&[data-scene="dock-17"] {
							left: 10%;
							top: 42%;
						}

						&[data-scene="foyer-2-1-a"] {
							left: 18%;
							top: 14.5%;
						}

						&[data-scene="foyer-2-2-a"] {
							left: 46%;
							top: 53.5%
						}

						&[data-scene="foyer-2-3"] {
							left: 45%;
							top: 82.3%;
						}

						&[data-scene="rtm-stage-2"] {
							left: 82%;
							top: 67%;
						}

						/*
						Floor 3 ******************************
						Floor 3 ******************************
						Floor 3 ******************************
						Floor 3 ******************************
						*/
						&[data-scene="port-1"] {
							left:14%;
							top:45.5%;
						}

						&[data-scene="port-3"] {
							left:19%;
							top:17.5%;
						}

						&[data-scene="port-4"] {
							left:9%;
							top:17.5%;
						}

						&[data-scene="port-5"] {
							left:31%;
							top:3%;
						}

						&[data-scene="port-7"] {
							left:81%;
							top:47.5%;
						}

						&[data-scene="port-8"] {
							left:55.7%;
							top:55.5%;
						}

						&[data-scene="rotterdam"] {
							left:33.7%;
							top:78.2%;
						}

						&[data-scene="foyer-3-2-a"] {
							left: 25.5%;
							top: 8.3%;
						}

						&[data-scene="foyer-3-1-a"] {
							left: 45.5%;
							top: 57%;
						}

						&[data-scene="rtm-stage-3"] {
							left: 82%;
							top: 67%;
						}

					}

				}

			}
			
		}

	}

	.logo {
		position:absolute;
		top:0;
		left:0;
		width:217px;
		height:80px;
		background-image:url('../img/static/logo.png');
		background-size:contain;
		background-repeat:no-repeat;
		background-position:center;
		z-index:100;

		@include sm {
			width:133px;
			height:50px;
		}
	}

	.button {

		position:absolute;
		top:0;
		width:80px;
		height:80px;
		background-color:#000;
		z-index:100;

		&.map {
			right:90px;

			@include sm {
				right:60px;
			}
		}
		
		&.menu {
			right:0px;	
		}

		@include sm {
			width:50px;
			height:50px;
		}

		.icon {
			position:absolute;
			width:40px;
			height:40px;
			left:50%;
			top:50%;
			transform:translate(-50%,-50%);
			background-position:center;
			background-repeat:no-repeat;
			background-size:contain;

			@include sm {
				width:25px;
				height:25px;
			}

			&.map {
				background-image:url('../img/static/icon-map.svg');
			}

			&.menu {
				background-image:url('../img/static/icon-menu.svg');
			}

			&.close {
				background-image:url('../img/static/icon-close.svg') !important;
			}

		}

	}

	/*
	Overlay
	*/
	.overlay {
		display:none;
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:50;

		&.menu {
			background-color:#1B232A;
		}
		&.map {
			background-color:#4C5C65;
		}

		.floor,
		.floorplan {
			display:none;
			float:left;
			width:100%;

			&.active {
				display:block;
			}
		}

		.floor-button {
			float:left;
			width:100%;
			margin-bottom:30px;
			cursor:pointer;

			@include sm {
				margin-bottom:10px;
			}

			&:hover,
			&.active {
				.label {
					color:rgba(255,255,255,1);
				}

			}

			.label {
				float:left;
				font-size:30px;
				color:rgba(255,255,255,0.3);
				font-weight:bold;
				transition:all 0.2s;

				@include sm {
					font-size:15px;
				}
			}

		}

		/*
		Menu ***************************************
		Menu ***************************************
		Menu ***************************************
		Menu ***************************************
		*/
		.menu {
			float:left;
			width:100%;
			margin-top:120px;

			@include sm {
				margin-top:80px;
			}

			.scene-button {

				float:left;
				width:100%;
				margin-bottom:15px;
				padding-bottom:15px;
				border-bottom:1px solid #707070;
				cursor:pointer;

				&:hover,
				&.active {
					.label {
						color:rgba(255,255,255,1);
					}

				}

				.label {
					float:left;
					font-size:30px;
					color:rgba(255,255,255,0.3);
					font-weight:bold;
					transition:all 0.2s;

					@include sm {
						font-size:15px;
					}

				}

				.icon {
					float:right;

					svg {
						float:right;
						margin-top:10px;
						margin-right:20px;
						width:25px;
						height:25px;
						fill:#FFED00;

						@include sm {
							width:10px;
							height:10px;
						}

					}

				}

			}

			.scenes {
				float:left;
				width:100%;
				height:calc(100vh - 240px);
				min-height:400px;
				overflow:auto;

				@include sm {
					height:calc(100vh - 120px);
				}
			}

		}

		/*
		Map ***************************************
		Map ***************************************
		Map ***************************************
		Map ***************************************
		*/
		.map {
			float:left;
			width:100%;
			margin-top:120px;

			.maps {
				float:left;
				width:100%;
				height:calc(100vh - 240px);
				min-height:400px;
				overflow:auto;

				@include sm {
					height:calc(100vh - 120px);
				}

				.floorplan {
					float:left;
					width:99%;

					.responsive-map {
						position:relative;
						display: inline-block;

						img {
						  display: block;
						  max-width: 99%;
						  height: auto;
						}

						.marker {
							position:absolute;
							width:20px;
							height:20px;
							top:0;
							left:0;
							border-radius:50%;
							background-color:#000;
							z-index:2;
							cursor:pointer;

							&.active {
								background-color:#ffed00;
								border:1px solid #000;
								animation: 1s blink ease infinite;
							}

							&:hover {
								background-color:#ffed00;
								transition:0.4s all;
							}

							@include xl {
								width:10px;
								height:10px;
							}

							@include sm {

								div {
									position:absolute;
									top:50%;
									left:50%;
									width:30px;
									height:30px;
									transform:translate(-50%,-50%);
									// border:1px solid #000;
								}
								
							}

							/*
							Floor 1 ******************************
							Floor 1 ******************************
							Floor 1 ******************************
							Floor 1 ******************************
							*/
							&[data-scene="entrance-RTM-B"] {
								left: 42.5%;
								top: 82%;
							}

							&[data-scene="hal-1"] {
								left:20%;
								top:19%;
							}

							&[data-scene="hal-2"] {
								left:60.5%;
								top:40%;
							}

							&[data-scene="hal-3"] {
								left:60.5%;
								top:62%;
							}

							&[data-scene="hal-4"] {
								left:74.5%;
								top:40%;
							}

							&[data-scene="hal-5"] {
								left:74.5%;
								top:62%;
							}

							&[data-scene="hal-6"] {
								left:89%;
								top:52%;
							}

							&[data-scene="bar-restaurant-seventy-one"] {
								left: 47%;
								top: 64.5%;
							}

							&[data-scene="ahoy-plaza"] {
								left:47%;
								top:52%;
							}

							&[data-scene="ahoy-arena"] {
								left:25.5%;
								top:52%;
							}

							&[data-scene="entree-ahoy-arena"] {
								left:25.5%;
								top:73%;
							}

							&[data-scene="rtm-stage-1"] {
								left: 66.5%;
								top: 81%;
							}

							/*
							Floor 2 ******************************
							Floor 2 ******************************
							Floor 2 ******************************
							Floor 2 ******************************
							*/
							&[data-scene="dock-1"] {
								left:18%;
								top:25.7%;
							}

							&[data-scene="dock-3"] {
								left:41%;
								top:28.5%;
							}

							&[data-scene="dock-4"] {
								left:41%;
								top:35%;
							}

							&[data-scene="dock-6"] {
								left:49.5%;
								top:61.7%
							}

							&[data-scene="dock-10"] {
								left:64%;
								top:88.5%;
							}

							&[data-scene="dock-12"] {
								left:39%;
								top:72.3%;
							}

							&[data-scene="dock-13"] {
								left:37%;
								top:87.7%;
							}

							&[data-scene="dock-14"] {
								left:19.5%;
								top:87.89%;
							}

							&[data-scene="dock-15"] {
								left:19.5%;
								top: 77.5%;
							}

							&[data-scene="dock-16"] {
								left: 20%;
								top: 42%;
							}

							&[data-scene="dock-17"] {
								left: 10%;
								top: 42%;
							}

							&[data-scene="foyer-2-1-a"] {
								left: 18%;
								top: 14.5%;
							}

							&[data-scene="foyer-2-2-a"] {
								left: 46%;
								top: 53.5%
							}

							&[data-scene="foyer-2-3"] {
								left: 45%;
								top: 82.3%;
							}

							&[data-scene="rtm-stage-2"] {
								left: 82%;
								top: 67%;
							}

							/*
							Floor 3 ******************************
							Floor 3 ******************************
							Floor 3 ******************************
							Floor 3 ******************************
							*/
							&[data-scene="port-1"] {
								left:14%;
								top:45.5%;
							}

							&[data-scene="port-3"] {
								left:19%;
								top:17.5%;
							}

							&[data-scene="port-4"] {
								left:9%;
								top:17.5%;
							}

							&[data-scene="port-5"] {
								left:31%;
								top:3%;
							}

							&[data-scene="port-7"] {
								left:81%;
								top:47.5%;
							}

							&[data-scene="port-8"] {
								left:55.7%;
								top:55.5%;
							}

							&[data-scene="rotterdam"] {
								left:33.7%;
								top:78.2%;
							}

							&[data-scene="foyer-3-2-a"] {
								left: 25.5%;
								top: 8.3%;
							}

							&[data-scene="foyer-3-1-a"] {
								left: 45.5%;
								top: 57%;
							}

							&[data-scene="rtm-stage-3"] {
								left: 82%;
								top: 67%;
							}

						}

					}

				}

			}

		}
		
	}

}

/*
Info box **************************************************
Info box **************************************************
Info box **************************************************
Info box **************************************************
*/
.info-box {
	display:none;
	position:fixed;
	right:-600px;
	bottom:0;
	width:600px;
	padding-bottom:30px;
	background-color:#000;
	text-align:left;
	z-index:30;

	@include sm {
		padding-bottom:0;
	}

	// border-top-left-radius:30px;

	@include sm {
		width:100%;
		right:-100%;
	}

	.inside {

		float:left;
		width:100%;
		padding:30px;

		@include sm {
			padding:20px;
		}

		h2 {
			float:left;
			width:100%;
			padding:0;
			margin:0;
			padding-bottom:15px;
			font-size:25px;
			font-weight:bold;
			color:#fff;

			@include sm {
				font-size:20px;
				line-height:16px;
				padding-bottom:0;
			}
		}

		.scroller {
			float:left;
			width:100%;
			margin-top:20px;
			max-height:calc(100vh - 200px);
			overflow:auto;

			@include sm {
				margin-top:10px;
			}

			.textblock-1 {
				@include md {
					width:80%;
				}
			}

			.button {
				//margin-bottom:40px;
			}

			.in {
				float:left;
				padding:20px;
			}

		

			p {
				float:left;
				width:100%;
				padding-top:0;
				padding-bottom:0;
				color:#fff;

				@include sm {
					font-size:12px;
					line-height:16px;
				}

			}

			ul, ul li {
				float:left;
				font-size:14px;
				line-height:22px;
				width:100%;
				color:#000;

				@include sm {
					font-size:12px;
					line-height:20px;
				}

			}

			// a {
			// 	float:left;
			// 	font-size:14px;
			// 	line-height:22px;
			// 	width:100%;
			// 	padding:20px;
			// 	padding-top:0;
			// 	padding-bottom:0;
			// 	color:#000;
			// 	text-decoration: underline;

			// 	@include sm {
			// 		font-size:12px;
			// 		line-height:16px;
			// 	}

			// }

			.table {
				float:left;
				width:100%;
				padding:20px;
				padding-top:0;
				font-size:16px;
				line-height:25px;
				color:#000;

				.title {
					background-color:#9d2236;
					color:#fff;
					font-weight:bold;
				}

				table {
					width:100%;
				}

				tr { 
				}

				td {
					border-left:1px solid #ccc;
					border-right:1px solid #ccc;
					border-bottom:1px solid #ccc;
					padding:2px;
					padding-left:8px;
					padding-right:8px;
				}
			}

		}

		h3 {
			float:left;
			width:100%;
			padding:0;
			margin:0;
			padding:20px;
			padding-top:10px;
			padding-bottom:10px;
			font-weight:normal;
			font-size:20px;
			border-bottom:1px solid #ccc;
			color:#000;

			@include sm {
				font-size:15px;
				line-height:16px;
			}

		}

		// a {
		// 	float:left;
		// 	width:100%;

		// 	&.first {
		// 		margin-top:10px;
		// 	}

		// 	.icon {
		// 		float:left;
		// 		width:20px;
		// 		height:20px;

		// 		svg {
		// 			fill:#9D2236;
		// 		}

		// 	}
			
		// 	.label {
		// 		float:left;
		// 		margin-left:10px;
		// 		color:#000;
		// 		text-decoration:none;
		// 	}
		// }

	}

	.puller {
		display:none;
		position:absolute;
		left:-140px;
		bottom:40px;
		width:140px;
		height:60px;
		// border-top-left-radius:30px;
		// border-bottom-left-radius:30px;
		background-color:#ffed00;
		cursor:pointer;

		@include sm {
			bottom:20px;
		}

		&.active {
			left:-60px;
			width:60px;
		}

		label {
			float:left;
			width:40px;
			margin-left:20px;
			margin-top:12px;
			font-size:14px;
			line-height:18px;
			color:#000;
			font-weight:bold;
			text-align:center;
			cursor:pointer;
			opacity:0.5;

			@include sm {
				font-size:12px;
				line-height:16px;
			}
		}

		.icon {
			float:left;
			border-radius:50%;
			margin-top:10px;
			margin-left:10px;

			&.rotate {
				transform:rotate(-180deg);
			}

			svg {
				float:left;
				width:40px;
				height:40px;
			}

		}

	}
	
}