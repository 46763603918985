@import "_default.scss_include-mixins";

footer {

	.copyright {
		position:fixed;
		left:20px;
		bottom:20px;
		background-color:#fff;
		width:35px;
		height:35px;
		background-image:url('./../img/static/copyright.png');
		background-size:75%;
		border-radius:10px;
		background-position:center;
		background-repeat:no-repeat;
		opacity:0.5;
		transition:all 0.2s ease-in;
		z-index:200;

		@include sm {
			width:20px;
			height:20px;
			opacity:0.3;
			border-radius:5px;
		}

		&:hover {
			opacity:1;
		}
	}

}