@import "_default.scss_include-mixins";

.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	top:-9999px;
	width:100%;
	height:100%;
	background-color:#fff;
	z-index:9999;
	overflow:auto;

	.exit {
		position:absolute;
		top:40px;
		right:50px;
		width:40px;
		height:40px;
		cursor:pointer;
		z-index:100;

		@include sm {
			top:20px;
			right:20px;
			width:30px;
			height:30px;
		}

		svg {
			fill:#000;
		}

	}

	.logo {
		display:inline-block;
		width:100%;
		height:70px;
		margin-top:60px;
		margin-bottom:10px;
		background-image:url('../img/static/logo.svg');
		background-size:contain;
		background-repeat:no-repeat;
		background-position:center;
	}
	
	.scroller {
		position:relative;
		width:100vw;
		height:100vh;
	}

	.inside {
		position:relative;
		float:left;
		width:100%;
		text-align:center;
		color:#000;
		padding:20px;
		padding-top:0;
		padding-bottom:40px;

		.logo {
			float:left;
			width:100%;
			height:80px;
			background-image:url('../img/static/logo.svg');
			background-size:contain;
			background-repeat:no-repeat;
			background-position:center;
		}

		h2 {
			font-size:25px;
		}

		nav {
			float:left;
			width:100%;
			margin-top:20px;
			margin-bottom:20px;

			a {
				float:left;
				font-family:'MetaPlus';
				width:100%;
				margin-bottom:14px;
				font-size:18px;
				line-height:20px;
				text-align:center;
				text-decoration:none;
				color:#000;
				opacity:0.6;

				&:hover,
				&.active {
					color:#000;
					opacity:1;
				}

			}

		}

		.box {
			float:left;
			width:100%;
			text-align:center;
			
			.icon {
				display:inline-block;
				width:40px;
				height:30px;
				margin-bottom:20px;

				svg {
					width:40px;
					height:30px;
				}
			}
		}



	}

}