@import "_default.scss_include-mixins";

.page.content--tour {

	#tour {
		display:none;
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;

		background-image:url(../img/static/assets/slick-loader.gif);
		background-position:center;
		background-color:#fff;
		background-repeat:no-repeat;
		background-size:2%;
	}

	.debug {
		display:none;
		position:fixed;
		bottom:0;
		left:50%;
		width:200px;
		margin-left:-100px;
		padding:20px;
		padding-top:10px;
		padding-bottom:10px;
		text-align:left;
		z-index:10;
		font-size:15px;
		line-height:25px;
		background-color:#fff;
		color:#000;
	}

}